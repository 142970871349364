import React from "react";

import { makeStyles } from "@material-ui/core/styles";
//import styles from "../components/Card/CardStyles.js";

import ImageBG1 from "../assets/img/chemical_dna.png";
import ImageBiotec from "../assets/img/Logo-Biotec.png";
import ImageTBRC from "../assets/img/Logo-TBRC.png";
import ImageImcas from "../assets/img/Logo-IMCAS-07-1.png";
import ImageAnso from "../assets/img/Logo-ANSO-08-1.png";
import ImageIBAS from "../assets/img/Logo-Academy.png";
import ImageInofBotani from "../assets/img/Logo-InofBotani-10-1.png";

import imagesStyles from "../assets/jss/imagesStyles.js";
import { cardTitle, cardLink } from "../assets/jss/common.js";
import Banner from "../components/Banner/Banner";

const styles = {
  ...imagesStyles,
  cardTitle,
  cardLink,
};

const useStyles = makeStyles(styles);

export default function NewsCard01(props) {
  const classes = useStyles();
  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <Banner bannerTitle="People" bannerImg={ImageBG1} />

        <div class="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 p-9">
          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-20 md:mt-20 text-center">
              <h2 class="mt-20 md:mt-20 text-3xl text-yellow-500 font-semibold tracking-wide uppercase">
                Our international members
              </h2>
              <p class="mt-2 text-2xl leading-8 font-extrabold tracking-tight">
                3 Asian research institutes
              </p>
            </div>
          </section>

          <section class="relative bg-blueGray-100 mt-20 md:mt-20 space-y-7">
            <figure class="md:flex bg-gray-100 rounded-xl md:p-0 shadow-md">
              <div class="md:flex-shrink-0 p-8">
                <a href="https://www.biotec.or.th/" target="_blank">
                  <img
                    class="h-auto w-70 md:w-80 md:h-auto md:rounded-none mx-auto object-cover p-2"
                    src={ImageBiotec}
                    alt=""
                  />
                </a>
                <a href="https://www.tbrcnetwork.org/" target="_blank">
                  <img
                    class="h-auto w-70 md:w-80 md:h-auto md:rounded-none mx-auto object-cover p-2"
                    src={ImageTBRC}
                    alt=""
                  />
                </a>
              </div>
              <div class="pt-6 md:pt-8 space-y-4 p-8">
                <blockquote>
                  <p class="text-2xl font-medium">
                    <a
                      href="https://www.tbrcnetwork.org/"
                      target="_blank"
                      class="hover:text-blue-800"
                    >
                      Thailand Bioresource Research Center (TBRC)
                    </a>
                  </p>
                </blockquote>
                <figcaption class="font-normal">
                  <p
                    style={{ textIndent: "40px" }}
                    class="text-lg font-light leading-normal tracking-wide"
                  >
                    Thailand Bioresource Research Center (TBRC), which is one of
                    the research centers under National Center for Genetic
                    Engineering and Biotechnology (BIOTEC), National Science and
                    Technology Development Agency (NSTDA), has extensive
                    expertise in microbial and microbiome research. TBRC
                    researchers employs state-of-the-art high throughput and
                    bioinformatic techniques to analyze metagenomic samples to
                    gain insights into functional potentials of microbes and
                    their interaction in microbial communities.
                    <a
                      href="https://amibase.org/"
                      target="_blank"
                      class="mt-2 text-blue-800 hover:text-yellow-300"
                    >
                      <a> </a>ASEAN Microbial Database (AmiBase)
                    </a>
                    , which has developed to facilitate research on microbes and
                    microbiome in Southeast Asia, is one of TBRC’s recent
                    achievements. TBRC working team is led by Dr. Supawadee
                    Ingsriswang.
                  </p>

                  {/*<div class="text-gray-500">
                    <a
                      href="http://www.biotec.or.th"
                      target="_blank"
                      class="mt-2 text-gray-500 whitespace-pre hover:text-yellow-300"
                    >
                      www.biotec.or.th,
                    </a>

                    <a
                      href="https://www.tbrcnetwork.org/"
                      target="_blank"
                      class="mt-2 text-gray-500 whitespace-pre hover:text-yellow-300"
                    >
                      www.tbrcnetwork.org
                    </a>
                    </div>*/}
                </figcaption>
                <div class="grid md:grid-cols-3 gap-1 flex-col md:flex">
                  <div class="text-base font-light leading-normal tracking-wide">
                    <p>TBRC team member: </p>
                    <p>1. Dr. Supawadee Ingsriswang</p>
                    <p>2. Dr. Chitwadee Phithakrotchanakoon</p>
                    <p>3. Dr. Piyanun Harnpicharnchai</p>
                    <p>4. Dr. Somsak Likhitrattanapisal</p>
                    <p>5. Dr. Apirak Wiseschart</p>
                    <p>6. Ms. Supattra Kitikhun</p>
                    <p>7. Mr. Worawongsin Boonsin</p>
                    <p>8. Mr. Paopit Siriarchawatana</p>
                    <p>9. Ms. Sermsiri Mayteeworakoon</p>
                    <p>10. Ms. Mintra Seesang</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure class="md:flex bg-gray-100 rounded-xl  md:p-0 shadow-md">
              <div class="pt-6 md:pt-8 space-y-4 p-8">
                <blockquote>
                  <p class="text-2xl font-medium">
                    <a
                      href="http://www.im.cas.cn/"
                      target="_blank"
                      class="hover:text-blue-800"
                    >
                      The Institute of Microbiology, Chinese Academy of Sciences
                      (IMCAS)
                    </a>
                  </p>
                </blockquote>
                <figcaption class="font-normal">
                  <p
                    style={{ textIndent: "40px" }}
                    class="text-lg font-light leading-normal tracking-wide"
                  >
                    The Institute of Microbiology, Chinese Academy of Sciences
                    (IMCAS) is one of Asia’s leading research institutes in
                    environmental microbiome and metagenomic research. One of
                    their achievements in the field is
                    <a
                      href="https://gcmeta.wdcm.org/"
                      target="_blank"
                      class="mt-2 text-blue-800 hover:text-yellow-300"
                    >
                      <a> </a>The Global Catalogue of Metagenomics (gcMeta)
                      <a> </a>
                    </a>
                    which is an international-scaled microbial data platform for
                    data management, analysis, and publication services for
                    microbiome-related study. IMCAS working team is led by Prof.
                    Dr. Lei Cai.
                  </p>
                </figcaption>
                <div class="grid md:grid-cols-3 gap-1 md:gap-0 flex-col md:flex ">
                  <div class="text-base font-light leading-normal tracking-wide">
                    <p>IMCAS team member: </p>
                    <p>1. Prof. Dr. Lei Cai</p>
                    <p>2. Asst. Prof. Dr. Junmin Liang</p>
                    <p>3. Dr. Xin Zhou</p>
                    <p>4. Ms. Meng Li</p>
                  </div>
                </div>
              </div>
              <div class="md:flex-shrink-0 p-8">
                <a href="http://www.im.cas.cn/" target="_blank">
                  <img
                    class="h-auto w-60 md:w-60 md:h-auto md:rounded-none mx-auto object-cover p-1"
                    alt=""
                    src={ImageImcas}
                    alt="Card-img-cap"
                  />
                </a>
              </div>
            </figure>

            <figure class="md:flex bg-gray-100 rounded-xl  md:p-0 shadow-md">
              <div class="grid grid-cols-2 md:grid-cols-2 flex-shrink-0 p-8">
                <a href="http://www.academy.uz/en" target="_blank">
                  <img
                    class="h-40 w-50 md:rounded-none mx-auto object-cover p-2"
                    src={ImageIBAS}
                  />
                </a>
                <a href="https://botany.uz/en/" target="_blank">
                  <img
                    class="h-40 w-50 md:rounded-none mx-auto object-cover p-2"
                    src={ImageInofBotani}
                  />
                </a>
              </div>
              <div class="pt-6 md:pt-8 space-y-4 p-8">
                <blockquote>
                  <p class="text-2xl font-medium">
                    <a
                      href="https://botany.uz/en/"
                      target="_blank"
                      class="hover:text-blue-800"
                    >
                      Institute of Botany,
                    </a>
                    <a
                      href="http://www.academy.uz/en"
                      target="_blank"
                      class="hover:text-blue-800"
                    >
                      Academy of Sciences of the Republic of Uzbekistan
                    </a>
                  </p>
                </blockquote>
                <figcaption class="font-normal">
                  <p
                    style={{ textIndent: "40px" }}
                    class="text-lg font-light leading-normal tracking-wide"
                  >
                    Institute of Botany, Academy of Sciences of the Republic of
                    Uzbekistan (IBAS), is the third participating research
                    institute in this project. IBAS working team is led by Dr.
                    Yusufjon Gafforov, who has wide-ranging experiences on
                    fungal diversity, especially taxonomic and phylogenetic
                    analysis.
                  </p>
                </figcaption>
                <div class="grid md:grid-cols-3 gap-1 md:gap-0 flex-col md:flex mx-1 ">
                  <div class="text-base font-light leading-normal tracking-wide">
                    <p>IBAS team member: </p>
                    <p>1. Dr. Yusufjon Gafforov</p>
                    <p>2. Dr. Tutigul Kholmuradova</p>
                    <p>3. Ms. Shaxnoza Teshabaeva</p>
                  </div>
                </div>
              </div>
            </figure>

            <figure class="md:flex bg-gray-100 rounded-xl  md:p-0 shadow-md">
              <div class="pt-6 md:pt-8 space-y-4 p-8">
                <blockquote>
                  <p class="text-2xl font-medium">
                    <a
                      href="http://www.anso.org.cn"
                      target="_blank"
                      class="hover:text-blue-800"
                    >
                      Alliance of International Science Organizations (ANSO)
                    </a>
                  </p>
                </blockquote>
                <figcaption class="font-normal">
                  <p
                    style={{ textIndent: "40px" }}
                    class="text-lg font-light leading-normal tracking-wide"
                  >
                    This project is sponsored by ANSO. ANSO aims to build a
                    community of international cooperation on science,
                    technology, and innovation along the Belt and Road
                    initiative which is then expanded to other regions of the
                    world as well. This project is another example of how the
                    participating countries on the Belt and Road initiative can
                    come together with common interests and aspirations to
                    answer the goals of ANSO by using microbiome analysis to
                    understand dynamics of microorganism communities especially
                    in relation to urban air quality
                  </p>
                </figcaption>
              </div>
              <div class="md:flex-shrink-0 p-8">
                <a href="http://www.anso.org.cn" target="_blank">
                  <img
                    class="w-60 h-auto md:w-60 md:h-auto md:rounded-none mx-auto object-cover p-1"
                    alt=""
                    src={ImageAnso}
                    alt="Card-img-cap"
                  />
                </a>
              </div>
            </figure>
          </section>
        </div>
      </div>
    </div>
  );
}
