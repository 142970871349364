import { Card, CardContent } from "@material-ui/core";
import React from "react";
import ImageBG1 from "../assets/img/chemical_dna.png";
import { makeStyles } from "@material-ui/core/styles";
import Image2 from "../assets/img/subway_t.jpeg";
import Image3 from "../assets/img/Bangkok-BTS-MRT-Map-2021.png";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderColor: "#F59E0B",
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
});

export default function Background() {
  const classes = useStyles();

  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <section class="header relative pt-16 items-center flex h-96 max-h-860-px bg-primary">
          <div class="container mx-auto items-center flex flex-wrap">
            <div class="w-full md:w-7/12 lg:w-12/12 xl:w-12/12 px-4 ">
              <p class="flex font-semibold text-4xl text-white tracking-normal">
                SubwayDNA:
              </p>
              <p class="text-white text-3xl font-medium tracking-normal">
                The study of urban air microbiomes in subways of Asia’s megacities
              </p>
            </div>
          </div>
          <img
            class="absolute top-0 b-auto right-0 pt-16 sm:w-30 -mt-48 sm:mt-0 w-72"
            src={ImageBG1}
          />
        </section>

        <div class="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-10">
              <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
                <Card className={classes.root} variant="outlined">
                  <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
                    <CardContent>
                      <h2 class="font-semibold text-2xl text-black text-center tracking-normal">
                        Microbial world in air around us
                      </h2>
                      <p
                        style={{ textIndent: "40px" }}
                        class="text-lg text-gray-800 leading-normal tracking-wide font-light"
                      >
                        Air is one of the environmental elements that pervade
                        everywhere on Earth. With such obvious fact, it is
                        unsurprising that air bioparticles affect the life of
                        every human being. Microbes or microorganisms are one of
                        the substantial constituents in air bioparticles. Air
                        microbiome is a term we call the community of airborne
                        microbes or microorganisms. These air microbes or
                        microorganisms, such as bacteria, fungi, and protozoa,
                        can interact with human in various ways. While most of
                        them are benign, free-living species that do not harm
                        us, there are some pathogenic microbes which pose
                        serious health concerns.
                      </p>
                      <span />
                      <p
                        style={{ textIndent: "40px" }}
                        class="text-lg text-gray-800 leading-normal tracking-wide font-light"
                      >
                        The relationship between human and air microbes is not
                        one-way relationship. Human activities in
                        densely-populated urban areas emit a gargantuan amount
                        of air pollution, e.g., exhaust gases from fuel
                        combustion, soot, dust, volatile compounds, water vapor,
                        etc. Nowaday urban air pollution is one of the most
                        pertinent challenges across cities globally. Since our
                        air microbes necessarity use small airborne molucules as
                        resources for doing things like competition,
                        cooperation, and environment sensing, they also get
                        afftected by us. There are reports that, for example,
                        the airborne particulate matter (PM), such as PM2.5,
                        PM10, can alter the composition of microbes in air. The
                        changes of microbial composition in polluted air often
                        see an increase in harmful, pathogenic microbes. Or
                        worse, even harmless microbes, when accumulate in high
                        density, may cause allergies in people with compromised
                        immune system and/or susceptible groups in our society,
                        such as the elderly, young children.
                      </p>
                    </CardContent>
                    <div class="flex justify-center">
                      <CardContent>
                        <img
                          src={Image2}
                          alt="https://th.pngtree.com/freepng/"
                          style={{ width: "650px" }}
                        />

                        <p class="text-sm font-extralight text-center">
                          CR. low head PNG Designed By 588ku from{" "}
                          <a href="https://pngtree.com/" target="_blank">
                            Pngtree.com
                          </a>
                        </p>
                      </CardContent>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </section>

          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-10">
              <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-center">
                      What are we looking for in SubwayDNA?
                    </h2>
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      As the name suggests, our “SubwayDNA” project aims to
                      study air microbiomes in the subway stations.
                      Specifically, we now have planned to study subway air
                      microbiomes in 2 Asian megacities, namely Bangkok
                      (Thailand) and Beijing (People's Republic of China).
                    </p>
                    <div class="flex justify-center">
                      <CardContent>
                        <img src={Image3} style={{ width: "550px" }} />
                        <p class="text-sm font-extralight text-center">
                          a map of Bangkok’s MRT stations.
                        </p>
                        <p class="text-sm font-extralight text-center">
                          CR. TRAVEL HAPPY via{" "}
                          <a
                            href="http://travelhappy.info/bangkok-bts-and-mrt-map/"
                            target="_blank"
                          >
                            Travelhappy.info
                          </a>
                        </p>
                      </CardContent>
                    </div>

                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      Let’s say in a simple sense, we would like to capture the
                      “snapshots” of air microbiomes by collecting DNAs
                      (deoxyribonucleic acids), the genetic material of (almost)
                      all organisms on Earth. Thanks to advances in
                      Next-Generation Sequencing (NGS) and High-Throughput
                      Sequencing (HTS) technologies, we can collect DNAs from
                      air in any environment, including – of course – subway
                      stations, and analyze to find out the species and
                      abundance of their owners. This DNA-based method is proved
                      to be a rapid and inexpensive way to examine whole
                      organisms in environment at any given time. Thus, it opens
                      the opportunities to design experiments to answer research
                      questions as follows;
                    </p>
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      • Are there any differences in air microbial communities
                      between different cities?
                    </p>
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      • Which air factors; for instance, temperature, humidity,
                      PM2.5, or other pollution gases, contribute the most to
                      those differences in air microbes?
                    </p>
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      • Does each air factor affect microbiomes in different
                      cities in the same way?
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>

          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div>
              <div>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-center">
                      Why subway?
                    </h2>
                    <p
                      style={{ textIndent: "40px" }}
                      ya
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      Prevalence of rapid transit systems is one of the iconic
                      characteristics of modern megacities. The subway network,
                      as one of the urban commuting modes, is a built
                      environment that constantly exposes human commuters to the
                      mingling mixture of airborne particles and microbes. In
                      another word, it is one of the most frequenly-visited
                      points of contact between humans and air microbiomes in
                      the cities
                    </p>
                    <span />
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      Subway stations are semi-close environment. Architecture
                      and ventilation mechanics in underground subways can
                      affect the dynamics of air mixing. The traffic volume,
                      commuters’ lifestyles, and even metallic dusts caused by
                      friction between train wheels and tracks also contribute
                      to the quantity and composition of air suspended particles
                      of the subway. Undoubtedly, the understanding of complex
                      physical and biological interaction at
                      human-air-microbiome interfaces in subway network would be
                      especially helpful.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>

          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-10">
              <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-center">
                      To whom this project will benefit?
                    </h2>
                    <p
                      style={{ textIndent: "40px" }}
                      class="text-lg text-gray-800 leading-normal tracking-wide font-light whitespace-pre-wrap"
                    >
                      The success of the project will generally advance the
                      research of air microbiome, or microbiome in urban built
                      environments. The sharing of information, protocol
                      standardization, and methodological know-hows which are
                      stemmed from this collaborative projects will yield the
                      foundation not only for future research in scientific
                      communities, but also for application in public health and
                      business R&D sectors. Our ultimate goal is to provide an
                      alternative method in quantitative hazard assessment in
                      urban environment, which would be pivotal for
                      science-oriented policy making. We hope that our project
                      would benefits all stakeholders and general public as a
                      whole by contributing to the goal of sustainable economic
                      progress, improving human livelihood and wellbeing, and
                      supporting the development of air pollution mitigation
                      strategies for sustainable cities.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

//export default Home;
