import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import ImageBG1 from "../assets/img/chemical_dna.png";
import Banner from "../components/Banner/Banner";

export default function Publication() {
  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <Banner bannerTitle="Publication" bannerImg={ImageBG1} />

        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <section class="pb-40 relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-10">
              <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
                <Card>
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-left">
                      1.
                    </h2>
                    <p class="text-xl text-gray-800">
                      Harnpicharnchai, P., Siriarchawatana, P., Mayteeworakoon, S., Ingsrisawang, L., Likhitrattanapisal, S., Eurwilaichitr, L., & Ingsriswang, S. (2024). Interplay of xenobiotic-degrading and antibiotic-resistant microorganisms among the microbiome found in the air, handrail, and floor of the subway station. <i>Environmental Research</i>, 118269.
                      </p> 
                      <div>
                      <a href="https://doi.org/10.1016/j.envres.2024.118269"  target="_blank"
                      class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2">
                      https://doi.org/10.1016/j.envres.2024.118269</a>
                      </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-left">
                      2.
                    </h2>
                    <p class="text-xl text-gray-800">
                      Harnpicharnchai, P., Siriarchawatana, P., Pumkaeo, P., Likhitrattanapisal, S., Mayteeworakoon, S., Zhou, X., ... & Ingsriswang, S. (2023). Temporal dynamics in diversity and composition of the air mycobiome and dominant allergenic fungi in the subway environment. <i>Environmental DNA</i>. </p>
                      <a href="https://doi.org/10.1002/edn3.449"
                      target="_blank"
                      class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2">
                      https://doi.org/10.1002/edn3.449</a>
                   
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-left">
                      3.
                    </h2>
                    <p class="text-xl text-gray-800">
                      Siriarchawatana, P., Pumkaeo, P., Harnpicharnchai, P., Likhitrattanapisal, S., Mayteeworakoon, S., Boonsin, W., ... & Ingsriswang, S. (2023). Temporal, compositional, and functional differences in the microbiome of Bangkok subway air environment. <i>Environmental Research</i>, 219, 115065.</p> 
                      <a href="https://doi.org/10.1016/j.envres.2022.115065"
                      target="_blank"
                      class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2">
                      https://doi.org/10.1016/j.envres.2022.115065</a>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <h2 class="font-semibold text-2xl text-black text-left">
                      4.
                    </h2>
                    <p class="text-xl text-gray-800">
                     Harnpicharnchai, P., Pumkaeo, P., Siriarchawatana, P., Likhitrattanapisal, S., Mayteeworakoon, S., Ingsrisawang, L., ... & Ingsriswang, S. (2023). AirDNA sampler: An efficient and simple device enabling high-yield, high-quality airborne environment DNA for metagenomic applications. <i>PLoS One</i>, 18(6), e0287567 </p>
                     <a href="https://doi.org/10.1371/journal.pone.0287567"
                     target="_blank"
                      class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2">
                     https://doi.org/10.1371/journal.pone.0287567</a>   
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

//export default Home;
