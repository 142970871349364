import React from "react";

import Banner from "../../components/Banner/Banner";
import ImageBG1 from "../../assets/img/chemical_dna.png";
import ImgNews1 from "../../assets/img/eDNA collaborative.png";

export default function News1() {
  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <Banner bannerTitle="News/Events" bannerImg={ImageBG1} />

        <div class="container max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 p-9">
          <section class="relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="mt-20 md:mt-20 text-center">
              <h2 class="mt-20 md:mt-20 text-2xl text-yellow-500 font-semibold tracking-wide uppercase">
                News abount air and urban environment microbiomes
              </h2>
            </div>
          </section>

          <div class="grid md:grid-cols-1">
            <section class="pb-40 relative bg-blueGray-100 mt-20 space-y-7">
              <figure class="md:flex-shrink-0 bg-gray-100 rounded-xl p-2 shadow-md">
                <div class="md:flex-shrink-0 p-2">
                  <img
                    class=" md:w-80 md:h-auto md:rounded-none mx-auto object-cover p-1"
                    src={ImgNews1}
                    alt=""
                  />
                </div>
                <div class="pt-4 md:pt-2 space-y-4 p-2">
                  <blockquote>
                    <p class="text-2xl font-medium p-2">
                      The 14th China bioindustry convention ANSO International
                      forum on green technology 11<sup>th</sup> June 2021
                    </p>
                    <div>
                      <a
                        href="https://www.facebook.com/tbrcnetwork/posts/4120395658046610"
                        target="_blank"
                        class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2"
                      >
                        Read More
                      </a>
                    </div>
                  </blockquote>
                </div>
              </figure>
            </section>
         
            <section class="pb-40 relative bg-blueGray-100 mt-20 space-y-7">
              <figure class="md:flex-shrink-0 bg-gray-100 rounded-xl p-2 shadow-md">
                <div class="md:flex-shrink-0 p-2">
                  <img
                    class=" md:w-80 md:h-auto md:rounded-none mx-auto object-cover p-1"
                    src={ImgNews1}
                    alt=""
                  />
                </div>
                <div class="pt-4 md:pt-2 space-y-4 p-2">
                  <blockquote>
                    <p class="text-2xl font-medium p-2">
                     A team of Thai scientists joined the Global City Sampling Day (gCSD) organized by the Metagenomics and Metadesign of the Subways and Urban Biomes (MetaSUB) International consortium.
                    </p>
                    <div>
                      <a
                        href="https://www.facebook.com/tbrcnetwork/posts/pfbid02eThuQhnTogvMkHjM4bt3iJcCg3YQhgWjg5L36ToGnGAANHfvUyqJoPELMDfZkvLQl"
                        target="_blank"
                        class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2"
                      >
                        Read More
                      </a>
                    </div>
                  </blockquote>
                </div>
              </figure>
            </section>
          </div>
          
        </div>
      </div>
    </div>
  );
}

//export default Home;
