import { Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import ImageBG1 from "../assets/img/chemical_dna.png";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../components/Banner/Banner";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderColor: "#F59E0B",
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
});

export default function Database() {

  const classes = useStyles();

  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <Banner bannerTitle="Database" bannerImg={ImageBG1} />
      </div>

      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section class="pb-40 relative bg-blueGray-100 mt-20 md:mt-20">
          <div class="mt-10">
            <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10 ">
              {
                <Card>
                  <CardContent>
                    <h2 class="font-semibold text-4xl text-black text-left">
                      Subway air microbiome data
                    </h2>
                    <p class="text-2xl text-gray-800">
                     <a 
                      href="https://appdb.mekongdna.org/app/subwaydna-app/main-6631dd26c1ee6741067f7a5d" target="_blank" class="mt-2 text-blue-500 whitespace-pre hover:text-yellow-300 text-base p-2"> 
                      Click here to see larger infographic
                     </a>
                    </p>
                    <iframe width="80%" height="600" src="https://appdb.mekongdna.org/app/subwaydna-app/main-6631dd26c1ee6741067f7a5d?embed=true"></iframe>
                  </CardContent>
                </Card> 
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// export default Home;
