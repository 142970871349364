import { List, ListItem } from "@material-ui/core";
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
// @material-ui/icons
import { Apps, Home, Menu } from "@material-ui/icons";
import styles from "./HeaderLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/">
          About
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Data"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Menu}
          dropdownList={[
            <ListItem>
            <Link to="/database" className={classes.dropdownLink}>
              Database
            </Link>
            </ListItem>,
            <ListItem>
            <Link to="/publication" className={classes.dropdownLink}>
              Publication
            </Link>
            </ListItem>,
            //~ <ListItem disabled="true">
              //~ <Link to="/database" className={classes.dropdownLink}>
                //~ Database
              //~ </Link>
            //~ </ListItem>,

            //~ <ListItem disabled="true">
              //~ <Link to="/publication" className={classes.dropdownLink}>
                //~ Publication
              //~ </Link>
            //~ </ListItem>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/people">
          People
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="/news">
          News/Events
        </Link>
      </ListItem>
    </List>
  );
}
