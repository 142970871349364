import React from "react";

export default function Banner({ bannerTitle, bannerImg }) {
  return (
    <div>
      <section class="header relative pt-16 items-center flex h-96 max-h-860 bg-primary">
        <div class="container mx-auto items-center flex flex-wrap">
          <div class="w-full md:w-6/12 lg:w-12/12 xl:w-12/12 px-4 ">
            <h2 class="font-semibold text-4xl md:text-5xl text-white">
              {bannerTitle}
            </h2>
          </div>
        </div>
        <img
          class="absolute top-0 b-auto right-0 pt-16 -mt-48 sm:mt-0 w-72"
          src={bannerImg}
        />
      </section>
    </div>
  );
}
