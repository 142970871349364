import React from "react";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
//import Collaborators from "./pages/People";
import Header from "./components/Header/Header";
import Background from "./pages/Background";
import Footer from "./components/Footer/Footer";
import Database from "./pages/Database";
import Publication from "./pages/Publication";
import News from "./pages/News";
import People from "./pages/People";
import News1 from "./pages/news_content/News1";
import News2 from "./pages/news_content/News2";

function App() {
  return (
    <Router>
      <CssBaseline />
      <Header />
      <Switch>
        <Route exact path="/" component={Background} />
        <Route path="/People" component={People} />
        <Route path="/database" component={Database} />
        <Route path="/publication" component={Publication} />
        <Route path="/news" component={News} />
        <Route path="/news_content/News1" component={News1} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
