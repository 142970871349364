import React from "react";
import ImageBG1 from "../assets/img/chemical_dna.png";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderColor: "#F59E0B",
  },
});

function Home() {
  //const { ...rest } = props;

  const classes = useStyles();
  return (
    <div>
      <div class="max-w-7xl mx-auto">
        <section class="header relative pt-16 items-center flex h-screen max-h-860-px bg-primary">
          <div class="container mx-auto items-center flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-12/12 xl:w-12/12 px-4 ">
              <h2 class="font-semibold text-7xl text-white text-center">
                subwayDNA
              </h2>
              <p
                style={{ textIndent: "40px" }}
                class="mt-4 text-xl leading-relaxed text-white text-justify"
              >
                The Project “SubwayDNA” is a collaborative, frontier research
                project to investigate the relationship of air microbiomes with
                environmental factors in Asian subway station.
              </p>
            </div>
          </div>
          <img
            class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-550px"
            src={ImageBG1}
          />
        </section>

        {/*<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <section class="pb-40 relative bg-blueGray-100 mt-20 md:mt-20">
            <div class="lg:text-center md:text-center sm:text-center">
              <h2 class="text-5xl text-yellow-500 font-semibold tracking-wide uppercase">
                Transactions
              </h2>
              <p class="mt-2 text-4xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                A better way to send money
              </p>
              <p class="mt-4 max-w-2xl text-3xl text-gray-500 lg:mx-auto">
                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus
                magnam voluptatum cupiditate veritatis in accusamus quisquam.
              </p>
            </div>

            <div class="mt-10">
              <div class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <dt>
                      <p class="ml-8 text-4xl leading-6 font-medium text-gray-900">
                        Sample text
                      </p>
                    </dt>
                    <dd class="mt-4 ml-8 text-3xl text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </CardContent>
                </Card>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <dt>
                      <p class="ml-8 text-4xl leading-6 font-medium text-gray-900">
                        Sample text
                      </p>
                    </dt>
                    <dd class="mt-4 ml-8 text-3xl text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </CardContent>
                </Card>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <dt>
                      <p class="ml-8 text-4xl leading-6 font-medium text-gray-900">
                        Sample text
                      </p>
                    </dt>
                    <dd class="mt-4 ml-8 text-3xl text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </CardContent>
                </Card>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <dt>
                      <p class="ml-8 text-4xl leading-6 font-medium text-gray-900">
                        Sample text
                      </p>
                    </dt>
                    <dd class="mt-4 ml-8 text-3xl text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </dd>
                  </CardContent>
                </Card>
              </div>
            </div>
          </section>
  </div>*/}
      </div>
    </div>
  );
}

export default Home;
