import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ImageBiotec from "../../assets/img/Logo-Biotec.png";
import ImageTBRC from "../../assets/img/Logo-TBRC.png";
import ImageImcas from "../../assets/img/Logo-IMCAS-07-1.png";
import ImageAnso from "../../assets/img/Logo-ANSO-08-1.png";
import ImageIBAS from "../../assets/img/Logo-Academy.png";
import ImageInofBotani from "../../assets/img/Logo-InofBotani-10-1.png";
//import LogoFooter from "../../assets/img/LogoFooter.png";
//import styles from "./FooterStyle.js";

//const userStyles = makeStyles(styles);

const useStyles = makeStyles({
  main: {
    position: "relative",
    zIndex: "3",
    margin: "0px 30px 0px",
    marginTop: "50px",
  },
});

export default function Footer() {
  const classes = useStyles();
  return (
    <section class="pb-2 relative mt-20 md:mt-20 bg-primary bg-opacity-50">
      <div class="container mx-auto items-center md:flex flex-wrap">
        <div class="grid grid-rows-12 gap-2 md:p-5">
          <div class="row-span-3 col-span-1 mt-10 md:mt-10">
            <p class="text-sm">Contact us</p>
            <div class="col-span-1">
              <p class="text-sm leading-8">Address : </p>
              <p class="text-sm leading-8">
                Thailand Bioresource Research Center (TBRC), National Center for
                Genetic Engineering{" "}
              </p>
              <p class="text-sm leading-8">and Biotechnology (BIOTEC),</p>{" "}
              <p class="text-sm leading-8">
                Innovation Cluster 2 Tower B, 8th Floor
              </p>{" "}
              <p class="text-sm leading-8">
                143 Thailand Science Park, Phahonyothin Road,
              </p>{" "}
              <p class="text-sm leading-8">Khlong Nueng, Khlong Luang,</p>{" "}
              <p class="text-sm leading-8">Pathum Thani 12120,</p>{" "}
              <p class="text-sm leading-8">Thailand</p>
            </div>
            <p class="text-sm">Email : subwayDNA@nstda.or.th</p>
          </div>
        </div>
        <div class="grid grid-cols-6 p-2 md:p-5 items-center">
          {/*<img class="md:h-20 md:w-full" src={LogoFooter} />*/}

          <a href="https://www.biotec.or.th/" target="_blank">
            <img class="max-h-24 p-2 mx-auto" src={ImageBiotec} alt="" />
          </a>
          <a href="https://www.tbrcnetwork.org/" target="_blank">
            <img class="max-h-24 p-2 mx-auto" src={ImageTBRC} alt="" />
          </a>
          <a href="http://www.im.cas.cn/" target="_blank">
            <img
              class="max-h-24 p-2 mx-auto"
              src={ImageImcas}
              alt="Card-img-cap"
            />
          </a>
          <a href="http://www.academy.uz/en" target="_blank">
            <img class="max-h-24 p-2 mx-auto" src={ImageIBAS} />
          </a>
          <a href="https://botany.uz/en/" target="_blank">
            <img class="max-h-24 p-2 mx-auto" src={ImageInofBotani} />
          </a>
          <a href="http://www.anso.org.cn" target="_blank">
            <img
              class="max-h-24 p-2 mx-auto"
              src={ImageAnso}
              alt="Card-img-cap"
            />
          </a>
        </div>
      </div>
      <div class="container md:container md:mx-auto flex justify-center">
        <div class="md:flex space-x-4 md:space-x-8">
          <div>
            <p class="text-sm leading-8 text-justify">
              Copyright © 2021 SubwayDNA All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
