import { Fab, Toolbar } from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";
import React from "react";

import Navbar from "../Navbar/Navbar";
import BackToTop from "../Scroll/scroll";
import HeaderLinks from "./HeaderLinks.js";
import LogoSubwayDNA from "../../assets/img/subwayDNA-logo-final-14.png";

export default function Header(props) {
  const { ...rest } = props;
  return (
    <div>
      <Navbar
        brand={LogoSubwayDNA}
        rightLinks="/"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnEcroll={{
          height: 550,
          color: "white",
        }}
        {...rest}
      />

      <Toolbar id="back-to-top-anchor" />

      <BackToTop>
        <Fab
          style={{ backgroundColor: "#F59E0B" }}
          size="large"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </div>
  );
}
